export default {
  data() {
    return {
      subscribers: [],
      handlers: {},
    };
  },
  beforeDestroy() {
    this.removeSubscribers();
  },
  methods: {
    initTemplates() {
      this.$nextTick(() => {
        if (!this.$refs.content) {
          console.warn("Please set refs with 'content' name on tag with dynamic content");
        } else {
          this.handleAccordionTemplate();
        }
      });
    },
    handleAccordionTemplate() {
      this.handlers.titleClick = evt => {
        const $parent = evt.target.parentElement;
        if ($parent.classList.contains("accordion--expand")) {
          $parent.classList.add("accordion--collapse");
          $parent.classList.remove("accordion--expand");
        } else {
          $parent.classList.remove("accordion--collapse");
          $parent.classList.add("accordion--expand");
        }
      };
      const $accordionEls = this.$refs.content.querySelectorAll(".accordion");
      $accordionEls.forEach(el => {
        const $title = el.querySelector(".accordion-title");
        $title.addEventListener("click", this.handlers.titleClick);
        this.subscribers.push({
          el: $title,
          handler: "titleClick",
          event: "click",
        });
      });
    },
    removeSubscribers() {
      this.subscribers.forEach(subscriber => {
        if (subscriber.el) {
          subscriber.el.removeEventListener(subscriber.event, this.handlers[subscriber.handler]);
        }
      });
    },
  },
};
