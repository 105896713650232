<template>
  <section class="corp-login-page" :style="theme">
    <header class="corp-login-page__header">
      <auth-header class="corp-login-page__logo" />
    </header>
    <div class="corp-login-page__body">
      <landing-lang-selector v-if="landingLangSelector" />
      <h1 class="corp-login-page__welcome">{{ welcomeText }}</h1>
      <div class="corp-login-page__buttons">
        <ws-button
          data-testid="systemTestLink"
          class="corp-login-page__btn"
          :lg="$mqFromTablet"
          color="primary"
          rounded
          @click="goToSystemTest"
        >
          {{ $t("systemTest.systemTest") }}
        </ws-button>
        <ws-button
          data-testid="loginButton"
          class="corp-login-page__btn"
          :lg="$mqFromTablet"
          color="primary"
          rounded
          :disabled="!pda || disableGuestLogin"
          @click="loginHandler"
        >
          {{ $t("common.login") }}
        </ws-button>
        <agreement v-model="pda" class="corp-login-page__agreement" />
      </div>
      <div
        v-show="corpContent"
        ref="content"
        class="corp-login-page__content templates-content"
        :style="`align-self: ${corpContentAlignment}`"
        v-html="corpContent"
      ></div>
    </div>
    <div class="corp-login-page__floated-buttons">
      <a v-if="supportPhone" data-testid="supportPhone" :href="`tel:${supportPhoneHref}`">
        <ws-button
          class="corp-login-page__floated-btn"
          data-testid="supportPhoneBtn"
          :lg="$mqFromTablet"
          color="primary"
          rounded
        >
          <ws-icon regular>phone</ws-icon>
          {{ supportPhone }}
        </ws-button>
      </a>
      <ws-button
        v-if="supportButtonEnabled"
        data-testid="supportModalBtn"
        class="corp-login-page__floated-btn"
        :lg="$mqFromTablet"
        color="primary"
        rounded
        @click="showSupportModal"
      >
        <ws-icon regular>envelope-open</ws-icon>
        {{ $t(`techSupport.${$mqMobile ? "techSupportShort" : "writeToSupportInline"}`) }}
      </ws-button>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import { SHOW_SUPPORT_MODAL } from "@/store/mutations/mutation-types";

import AuthHeader from "@/components/common/auth/AuthHeader";
import WsButton from "@/components/base/WsButton";
import Agreement from "@/components/common/elements/Agreement";
import WsIcon from "@/components/base/WsIcon";
import useLanding from "@/components/common/landing/mixins/useLanding";
import useComponentTemplates from "@/mixins/templates/useComponentTemplates";
import useLandingTheme from "@/components/common/landing/mixins/useLandingTheme";
import LandingLangSelector from "@/components/common/landing/LandingLangSelector";

/**
 * Page for corporate landing and login from it page for guests
 */
export default {
  name: "ViewCorpLogin",
  components: {
    LandingLangSelector,
    AuthHeader,
    Agreement,
    WsButton,
    WsIcon,
  },
  mixins: [useLanding, useLandingTheme, useComponentTemplates],
  data() {
    return {
      pda: false,
    };
  },
  computed: {
    supportPhoneHref() {
      return this.supportPhone.replace(/\(|\)|-|\s/g, "");
    },
  },
  created() {
    this.initTemplates();
  },
  methods: {
    ...mapMutations({
      showSupportModal: SHOW_SUPPORT_MODAL,
    }),
    /**
     * Redirect to system test by click on button from page
     *
     * @public
     */
    goToSystemTest() {
      this.$router.push({ name: "TestUserSystem" });
    },
    loginHandler() {
      if (this.isOAuth2Type) {
        this.oauth2Auth();
      } else {
        this.$router.push({ path: "/login", query: { guest: true } });
      }
    },
  },
};
</script>

<style lang="less">
@import "~@/styles/components/_templates";

.corp-login-page {
  --header-height: 100px;

  display: grid;
  grid-template-rows: var(--header-height) 1fr;
  gap: 30px 0;
  min-height: 100vh;

  @media (min-width: 768px) {
    --header-height: 200px;

    gap: 40px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    overflow: hidden;

    @media (min-width: 768px) {
      padding: 0 75px;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    max-width: 520px;
  }

  &__btn {
    font-size: 16px;
    font-weight: 400;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  &__agreement.agreement {
    grid-column: 1 / span 2;
    font-size: 16px;
  }

  &__welcome {
    max-width: 100%;
    margin-bottom: 1.1em;
    font-size: 24px;
    font-weight: 600;
    color: var(--login-input-color);

    @media (min-width: 480px) {
      max-width: 820px;
    }

    @media (min-width: 768px) {
      font-size: 30px;
    }

    @media (min-width: 1024px) {
      font-size: 44px;
    }
  }

  &__logo.logo {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__floated-buttons {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 10px;
  }

  &__content {
    max-width: 100%;
    padding: 20px 0;
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 16px;
    }

    h1 {
      font-size: 2.2em;
    }

    h2 {
      font-size: 1.8em;
    }

    h3 {
      font-size: 1.5em;
    }

    h4 {
      font-size: 1.1em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--login-input-color);
    }

    @media (min-width: 768px) {
      max-width: 500px;
    }

    @media (min-width: 1024px) {
      max-width: 768px;
    }

    .accordion {
      border-color: var(--login-input-color);
    }
  }
}
</style>
